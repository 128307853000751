import {
  Box,
  CardInput,
  Divider,
  Inline,
  PrimaryButton,
  RadioGroup,
  RadioItem,
  Stack,
  Text,
} from '@leadrilla/pulsar'
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useFetcher } from '../../hooks/fetcher'
import backend from '../../backend'
import { UserContext } from '../AuthProvider/auth_provider'
import { useNotification } from '../../hooks/notification'
import { SubscriptionDialogType } from './subscription_dialog'
import PaymentMethod from '../../types/payment_methods'
import { formatSubscription, formatUSD } from '../../helpers/currency'
import { toTitleCase } from '../../helpers/utils'
import { StripeIframe } from '../../AgentPortal/Marketplace/stripe_3ds_iframe'

const SubscriptionPayment = ({
  dialogType,
  updateSuccess,
  subscribeSuccess,
  subscriptionFee,
  currentPaymentMethod,
}: {
  dialogType: SubscriptionDialogType
  updateSuccess: any
  subscribeSuccess: any
  subscriptionFee?: number
  currentPaymentMethod?: string
}) => {
  let stripeRef = useRef({})
  const { user, setUser } = useContext(UserContext)
  const [redirectUrl, setRedirectUrl] = useState('')
  const [stripeDialogOpen, setStripeDialogOpen] = useState(false)

  useEffect(() => {
    window.addEventListener('message', async function (e) {
      if (e.data === '3DS-authentication-failed') {
        // @ts-expect-error FIXME
        showNotification({
          type: 'error',
          message: 'Authentication failed -- please refresh page and try again',
        })
        setSubmitting(false)
        setStripeDialogOpen(false)
      }
    })
    return () => window.removeEventListener('message', () => {})
  }, [])

  const showNotification = useNotification()

  // @ts-expect-error FIXME
  const { data: paymentMethods } = useFetcher('/payment-methods')
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')

  useEffect(() => {
    if (currentPaymentMethod) {
      setSelectedPaymentMethod(currentPaymentMethod)
    }
  }, [currentPaymentMethod])

  const [submitting, setSubmitting] = useState(false)
  const [newPaymentMethod, setNewPaymentMethod] = useState(null)

  const manageSubscription =
    // @ts-expect-error FIXME
    dialogType === SubscriptionDialogType.MANAGE && !user.subscription?.canceled_at

  const processPayment = async () => {
    setSubmitting(true)

    const submissionURL = manageSubscription ? '/subscriptions/update' : '/subscriptions/subscribe'
    const { status, body } = await backend.post(submissionURL, {
      payment_method: newPaymentMethod || selectedPaymentMethod,
      type: 'user-paid',
    })

    if (!body.success && body.redirectUrl) {
      setRedirectUrl(body.redirectUrl)
      setStripeDialogOpen(true)
      return
    }
    if (status === 200) {
      if (!manageSubscription) {
        if (body.subscription && body.subscription?.activated_at) {
          // @ts-expect-error FIXME
          setUser({ ...user, subscription: body.subscription })
          subscribeSuccess()
          // @ts-expect-error FIXME
        } else showNotification({ type: 'error' })
      } else {
        if (body.update) {
          updateSuccess()
          // @ts-expect-error FIXME
        } else showNotification({ type: 'error' })
      }
    } else {
      if (body.message.includes('Card Error -')) {
        // @ts-expect-error FIXME
        showNotification({ type: 'error', message: body.message })
      } else {
        // @ts-expect-error FIXME
        showNotification({ type: 'error' })
      }

      // Reset newPaymentMethod and selectedPaymentMethod on error
      setNewPaymentMethod(null)
      setSelectedPaymentMethod(currentPaymentMethod || '')
    }
    setSubmitting(false)
  }

  const onPaymentMethodChange = (event: any) => {
    const paymentMethod = event.target.value
    setSelectedPaymentMethod(paymentMethod)
  }

  const onNewPaymentMethodChange = async (event: { complete: any }) => {
    setSelectedPaymentMethod('new')
    if (event.complete) {
      // @ts-ignore
      const response = await stripeRef.current.createToken()

      if (response.token && response.token.id) {
        setNewPaymentMethod(response.token.id)
      }
    }
  }

  const submitDisabled =
    submitting || (!newPaymentMethod && !(selectedPaymentMethod && selectedPaymentMethod !== 'new'))

  return (
    <StyledBox
      width={['100%', '50%']}
      paddingLeft={['none', 'xl', 'xxl']}
      marginTop={['m', 'none']}
      paddingTop={['m', 'none']}
      display="flex"
      alignItems="center"
    >
      <Box width="100%">
        <Box marginBottom="l">
          <Text weight="stronger" size={['big', 'huge']}>
            {manageSubscription ? 'Change Payment Method' : 'Payment Information'}
          </Text>
        </Box>
        <Stack>
          {!manageSubscription && (
            <Box marginBottom="m">
              <Inline alignX="between">
                <Text size={['base', 'big']} tone="light" weight="strong">
                  Monthly Subscription Total
                </Text>
                <Text size="big" align="right" weight="stronger">
                  {subscriptionFee ? formatUSD(subscriptionFee) : ''}
                </Text>
              </Inline>
            </Box>
          )}

          <Divider />

          {paymentMethods && paymentMethods.length > 0 ? (
            <RadioGroup
              name="payment-method"
              value={selectedPaymentMethod}
              onChange={onPaymentMethodChange}
              paddingY="s"
              itemSpace="s"
              itemPaddingX="none"
              dividers
            >
              {paymentMethods &&
                paymentMethods.length > 0 &&
                paymentMethods.map((card: PaymentMethod) => (
                  <RadioItem
                    key={card.id}
                    label={`${toTitleCase(card.brand)} ending in ${card.last4}`}
                    value={card.id.toString()}
                  />
                ))}
              {paymentMethods?.length > 0 && <RadioItem label="New Payment Method" value="new" />}
            </RadioGroup>
          ) : (
            <Box marginY="s">
              <Text>Enter a payment method</Text>
            </Box>
          )}

          <Box marginBottom="l">
            <CardInput ref={stripeRef} onChange={onNewPaymentMethodChange} />
          </Box>

          {stripeDialogOpen && (
            <StripeIframe
              redirectUrl={redirectUrl}
              open={stripeDialogOpen}
              onClose={() => setStripeDialogOpen(false)}
            />
          )}

          <Stack space="s">
            <PrimaryButton
              size="bigger"
              disabled={submitDisabled}
              onClick={processPayment}
              fullWidth
            >
              {manageSubscription
                ? 'Change Payment Method'
                : // @ts-expect-error FIXME
                !user.subscription?.canceled_at
                ? 'Process Payment'
                : 'Resubscribe'}
            </PrimaryButton>
            <Text component="p" size="small">
              {manageSubscription ? (
                <>Your updated payment method will be charged on your next billing date.</>
              ) : (
                <>
                  Your card will be charged{' '}
                  {subscriptionFee ? formatSubscription(subscriptionFee) : ''} today and will renew
                  on the same day each month. You may cancel your subscription at any time.
                </>
              )}
            </Text>
          </Stack>
        </Stack>
      </Box>
    </StyledBox>
  )
}

const StyledBox = styled(Box)`
  border-top: 2px solid #d9d9d9;
  @media (min-width: 721px) {
    border-top: none;
    border-left: 2px solid #d9d9d9;
  }
`

export default SubscriptionPayment
