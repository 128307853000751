import { ColorValueHex, isColorValueHex } from '../styles/themes'
import { clsx, ClassValue } from 'clsx'
import { parsePhoneNumber } from 'libphonenumber-js'
import { twMerge } from 'tailwind-merge'
import moment from 'moment-timezone'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
  })
}

/**
 * Converts an uploaded `File` to a base64 string
 */
export const fileToBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

/**
 * Formats a number with commas, or returns a dash if the number is not greater than 0
 */
export const formatNumber = ({ n }: { n: number }) => {
  if (!(n > 0)) return '-'
  const formattedNumber = Intl.NumberFormat().format(n)
  return formattedNumber === '0' ? '-' : formattedNumber
}

// from https://blog.logrocket.com/applying-dynamic-styles-tailwind-css/
/**
 * Change hex color into RGB
 */
export const getRGBColor = (hex: string, type: string) => {
  try {
    let color = hex.replace(/#/g, '')
    // rgb values
    const r = parseInt(color.slice(0, 2), 16)
    const g = parseInt(color.slice(2, 4), 16)
    const b = parseInt(color.slice(4, 6), 16)

    return `--${type}: ${r}, ${g}, ${b};`
  } catch (error) {
    console.error(`could not parse hex color value: ${hex}`)
  }
}

/**
 * Determine the accessible color of text
 */
export const getAccessibleColor = (hex: string) => {
  try {
    let color = hex.replace(/#/g, '')
    // rgb values
    var r = parseInt(color.slice(0, 2), 16)
    var g = parseInt(color.slice(2, 4), 16)
    var b = parseInt(color.slice(4, 6), 16)
    var yiq = (r * 299 + g * 587 + b * 114) / 1000
    return yiq >= 128 ? '#000000' : '#FFFFFF'
  } catch (error) {
    console.error(`could not parse hex color value: ${hex}`)
  }
}

/**
 * Create css variables for all theme colors
 */
export type NestedObject = { [key: string]: string | ColorValueHex | NestedObject }

export const createThemeCssVariables = (
  theme: NestedObject,
  acc?: string[],
  path?: string
): string[] => {
  let classes: string[] = acc ?? []
  for (const key in theme) {
    const propertyPath = path ? path.concat(`-${key}`) : `-${key}`
    const val = theme[key]
    if (typeof val !== 'string' && !isColorValueHex(val)) {
      createThemeCssVariables(val, classes, propertyPath)
    } else if (isColorValueHex(val)) {
      const rgbColor = getRGBColor(val, `tenant-theme${propertyPath}`)
      if (rgbColor) classes.push(rgbColor)
    }
  }
  return classes
}

export const formatPhone = ({ number }: { number: string }) => {
  const phoneNumber = parsePhoneNumber(number, 'US')
  if (phoneNumber) {
    return phoneNumber.formatNational()
  }
  return number
}

export const getTimezone = ({ timezone }: { timezone: string }) => {
  const date = new Date()
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    timeZoneName: 'long',
  })
  const parts = formatter.formatToParts(date)
  const timeZoneName = parts.find((part) => part.type === 'timeZoneName')?.value || ''

  // For abbreviation, we can use 'short' instead of 'long'
  const formatterShort = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    timeZoneName: 'short',
  })
  const partsShort = formatterShort.formatToParts(date)
  const timeZoneAbbr = partsShort.find((part) => part.type === 'timeZoneName')?.value || ''

  return {
    time_zone_id: timezone,
    time_zone_name: timeZoneName,
    time_zone_abbr: timeZoneAbbr,
  }
}

export function getReadableDate({ timestamp, timezone }: { timestamp: number; timezone: string }) {
  const date = moment.unix(timestamp)
  return date.tz(timezone).format('dddd, MMMM Do, YYYY')
}

export function getReadableTimeSlot({
  timestamp,
  timezone,
}: {
  timestamp: number
  timezone: string
}) {
  /**
   * The timestamp passed in is a UNIX epoch timestamp. convert it
   * to the local time zone and return a readable time slot.
   */
  const date = moment.unix(timestamp)
  return date.tz(timezone).format('h:mma')
}

/**
 * Pluralizes an English word according to common rules
 * @param word The singular form of the word
 * @param count The number of items
 * @returns The word in its correct plural or singular form
 */
export const pluralize = ({ word, count }: { word: string; count: number }): string => {
  if (count === 1) return word

  // Special cases for words ending in 'y'
  if (word.endsWith('y')) {
    // If word ends in 'vowel + y', just add 's'
    if (['ay', 'ey', 'iy', 'oy', 'uy'].some((ending) => word.endsWith(ending))) {
      return `${word}s`
    }
    // Otherwise, replace 'y' with 'ies'
    return `${word.slice(0, -1)}ies`
  }

  // Words ending in 's', 'sh', 'ch', 'x', 'z' add 'es'
  if (word.match(/[sxz]$/) || word.match(/[cs]h$/)) {
    return `${word}es`
  }

  // Default case: add 's'
  return `${word}s`
}
