/* eslint no-undef: 0 */
import React, { useState, useEffect, useContext } from 'react'

import backend from '../../backend'
import { OK } from '../../constants/error_codes'
import { setUserProperties, resetIdentity, analyticsEvent } from '../../helpers/heap'
import * as Sentry from '@sentry/react'
import { User } from '../../types/users'
import { useHistory } from 'react-router-dom'

interface IUserContext {
  user: User | null
  setUser: (user: User, token?: string) => void
  removeUser: () => void
  refreshUser: () => void
  userHasRole: (params: { role: string; teamId: string }) => boolean
  logOut: () => void
  impersonate: ({ userId }: { userId: User['id'] }) => void
  cancelImpersonation: () => void
}

export const UserContext = React.createContext<IUserContext>({
  user: null,
  setUser: () => {},
  removeUser: () => {},
  refreshUser: async () => {},
  userHasRole: () => false,
  logOut: () => {},
  cancelImpersonation: async () => {},
  impersonate: ({ userId }: { userId: User['id'] }) => {},
})

export const useAuth = () => useContext(UserContext)

// @ts-expect-error FIXME
function AuthProvider(props) {
  const [user, setUserInState] = useState<User | null | undefined>()
  const history = useHistory()
  useEffect(() => {
    // @ts-expect-error FIXME
    if (window.fcWidget && user && user.id) {
      // @ts-expect-error FIXME
      const fc = window.fcWidget
      fc.setExternalId(user.id.toString())
      fc.user.setFirstName(user.first_name)
      fc.user.setLastName(user.last_name)
      fc.user.setEmail(user.email)
    }
    // @ts-expect-error FIXME
  }, [window.fcWidget, user])

  const setUser = (user: User, token?: string) => {
    if (token) {
      localStorage.setItem('token', token)
    }

    setUserInState(user)
    setUserProperties(user)
    try {
      Sentry.setUser({ id: user.id, email: user.email })
    } catch (e) {
      console.log(e)
    }
  }

  const removeUser = () => {
    localStorage.removeItem('token')

    setUserInState(null)

    resetIdentity()

    // @ts-expect-error FIXME
    if (window.fcWidget) {
      // @ts-expect-error FIXME
      window.fcWidget.user.clear()
    }
  }

  const refreshUser = async () => {
    if (localStorage.getItem('token')) {
      try {
        const resp = await backend.get('/users/me')

        if (resp.status === OK) {
          setUser(resp.body)
        } else {
          removeUser()
        }
      } catch (e) {
        console.log(e)
        removeUser()
      }
    } else {
      removeUser()
    }
  }

  const userHasRole = ({ role, teamId }: { role: string; teamId: string }) => {
    if (!user || !user.roles) return false
    return Boolean(
      // @ts-expect-error FIXME
      user.roles.find((userRole) => userRole.role === role && userRole.teamId === teamId)
    )
  }

  const logOut = () => {
    analyticsEvent('User Logged Out')
    removeUser()
    window.location.assign('/')
  }

  const cancelImpersonation = async () => {
    if (!user) return
    const {
      status,
      body: { jwt, user: u },
    } = await backend.get('/auth/cancel-impersonation')

    if (status === OK) {
      setUser(u, jwt)
      sessionStorage.clear()
      history.push(`/dash/users/${user.id}`)
    }
  }

  const impersonate = async ({ userId }: { userId: User['id'] }) => {
    const {
      status,
      body: { jwt, user: u },
    } = await backend.post('/auth/impersonate', { id: userId })

    if (status === OK) {
      setUser(u, jwt)
      await refreshUser()
      sessionStorage.clear()
      history.push('/dash/campaigns')
    }
  }

  useEffect(() => {
    refreshUser()
  }, [])

  if (user || user === null) {
    return (
      <UserContext.Provider
        value={{
          user,
          setUser,
          removeUser,
          refreshUser,
          userHasRole,
          logOut,
          cancelImpersonation,
          impersonate,
        }}
      >
        {props.children}
      </UserContext.Provider>
    )
  } else {
    return <></>
  }
}

export default AuthProvider
