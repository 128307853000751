import React, { useMemo } from 'react'
import { Call as TwilioCall } from '@twilio/voice-sdk'
import { useVoip, VoipActionType } from '../VoipProvider'
import { Dropdown, FieldMessage, PrimaryButton, TextArea, TextField } from '@leadrilla/pulsar'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { useNotification } from '../../../hooks/notification'
import { useStates } from '../../../hooks/queries/states'
import { useLeadStatuses } from '../../../hooks/queries/leadStatuses'
import { map } from 'lodash'
import backend from '../../../backend'
import { OK } from '../../../constants/error_codes'
import LeadTimeline from './LeadTimeline'
import { getLeadBirthdate } from '../../../helpers/voip'
import Spinner from 'src/components/ui/icons/spinner'

const formSchema = z.object({
  first_name: z.string().optional(),
  last_name: z.string().optional(),
  email: z.string().optional(),
  birthdate: z
    .string()
    .regex(/\d{4}-\d{2}-\d{2}/, 'Invalid date.')
    .or(z.literal('')),
  street_address: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
  notes: z.string().optional(),
  outcome: z.string().optional(),
})

type OutboundVoipCallDetailsFormData = z.infer<typeof formSchema>

const VoipOutboundCallDetailsWindow = ({ outboundCall }: { outboundCall?: TwilioCall }) => {
  const { voipState, voipDispatch } = useVoip()

  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isSubmitting },
  } = useForm<OutboundVoipCallDetailsFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      // We need the || '' here because if property doesn't have a value, it will be null and not allow user to save w/o filling it out bc it's not a string
      first_name: voipState.userLead?.first_name || '',
      last_name: voipState.userLead?.last_name || '',
      email: voipState.userLead?.email || '',
      birthdate: voipState.userLead ? getLeadBirthdate({ userLead: voipState.userLead }) : '',
      street_address: voipState.userLead?.street_address || '',
      city: voipState.userLead?.city || '',
      zip: voipState.userLead?.zip || '',
      state: voipState.userLead?.state || '',
    },
  })

  const { data: states } = useStates()
  const stateOptions = useMemo(
    () => states?.map(({ name, abbreviation }) => ({ text: name, value: abbreviation })) ?? [],
    [states]
  )

  const { data: leadStatuses } = useLeadStatuses({
    productId: voipState.userLead?.product_id,
    productType: 'calls', // This is a fallback for when voipState.userLead?.product_id is undefined (it often is)
  })
  const outcomeOptions = useMemo(
    () =>
      map(leadStatuses, ({ name }, key) => ({ text: name, value: key }))?.filter(
        ({ value }) => value !== 'NEW'
      ) ?? [],
    [leadStatuses]
  )

  const sendNotification = useNotification()

  const submitData = async (data: OutboundVoipCallDetailsFormData) => {
    if (!voipState.userLead?.lead_id) {
      // @ts-expect-error FIXME
      sendNotification({ type: 'error' })
    } else {
      // userLead.id refers to lead.id
      const { status } = await backend.put(`/leads/${voipState.userLead.lead_id}`, {
        ...data,
      })

      if (status !== OK) {
        // @ts-expect-error FIXME
        sendNotification({ type: 'error' })
      } else {
        // @ts-expect-error FIXME
        sendNotification({
          message: 'Your call outcome and lead info has been saved.',
        })
      }
    }
    voipDispatch({ type: VoipActionType.CLEAR_OUTBOUND_CALL })
  }

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="h-[399px] overflow-y-scroll pb-8">
        <div className="w-full rounded-xl bg-gray-50 px-4 py-6">
          <div className="flex justify-between space-x-8">
            <TextField label="First Name" labelWeight="stronger" {...register('first_name')} />
            <TextField label="Last Name" labelWeight="stronger" {...register('last_name')} />
          </div>
          <div className="flex items-center justify-between space-x-8 pt-[16px]">
            <TextField label="Email" labelWeight="stronger" {...register('email')} />
            <TextField
              type="date"
              label="Date of Birth"
              labelWeight="stronger"
              {...register('birthdate')}
            />
          </div>
          <div className="pt-[16px]">
            <TextField label="Address" labelWeight="stronger" {...register('street_address')} />
          </div>
          <div className="pt-[16px]">
            <TextField label="City" labelWeight="stronger" {...register('city')} />
          </div>
          <div className="flex items-center justify-between space-x-8 pt-[16px]">
            <Controller
              name="state"
              control={control}
              render={({ field }) => {
                const { ref, ...props } = field
                return (
                  <Dropdown
                    label="State"
                    labelWeight="stronger"
                    options={stateOptions}
                    optionsMaxHeight="150px"
                    {...props}
                  />
                )
              }}
            />
            <TextField label="Zip" labelWeight="stronger" {...register('zip')} />
          </div>
        </div>
        {voipState.userLead && (
          <div className="pt-4">
            <LeadTimeline leadId={voipState.userLead.id} />
          </div>
        )}
      </div>
      {/*<div className="w-px self-stretch bg-gray-100"></div>*/}
      <div className="flex flex-col justify-between">
        <div className="flex flex-grow flex-col space-y-10 px-4 pt-4">
          <Controller
            name="notes"
            control={control}
            render={({ field }) => {
              return (
                <TextArea
                  // @ts-ignore
                  className="h-[200px]"
                  rows={4}
                  label="Notes"
                  labelWeight="stronger"
                  placeholder="Enter note here..."
                  {...field}
                />
              )
            }}
          />
          <Controller
            name="outcome"
            control={control}
            render={({ field }) => {
              const { ref, ...props } = field
              return (
                <div>
                  <Dropdown
                    label="Change Status"
                    placeholder="Select a lead status"
                    options={outcomeOptions}
                    optionsMaxHeight="150px"
                    labelWeight="stronger"
                    native
                    {...props}
                  />
                  {errors.outcome && (
                    <div className="mt-[8px]">
                      <FieldMessage tone="negative">Please select a call status</FieldMessage>
                    </div>
                  )}
                </div>
              )
            }}
          />
          <PrimaryButton
            fullWidth
            disabled={outboundCall?.status() !== TwilioCall.State.Closed || isSubmitting}
            onClick={handleSubmit(submitData)}
            loading={isSubmitting}
          >
            {isSubmitting ? (
              <div className="flex items-center justify-center">
                <Spinner className="mr-2 size-5" color="white" />
                <span>Saving...</span>
              </div>
            ) : (
              'Save & continue'
            )}
          </PrimaryButton>
        </div>
      </div>
    </div>
  )
}

export default VoipOutboundCallDetailsWindow
