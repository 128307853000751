import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const SpinningSvg = styled.svg`
  animation: ${spin} 1s linear infinite;
`

export default function Spinner({
  color,
  className,
  thickness,
}: {
  color?: string
  className?: string
  thickness?: number
}) {
  return (
    <SpinningSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke={color || 'var(--colors-action)'}
      strokeWidth={thickness || 1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`size-4 ${className || ''}`}
    >
      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
    </SpinningSvg>
  )
}
