import React, { useContext, useEffect, useState } from 'react'
import { EventsType, IIdleTimerProps, useIdleTimer } from 'react-idle-timer'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { History } from 'history'
import { AnimatePresence, motion } from 'framer-motion'
import styled from '@emotion/styled'
import { formatDuration } from '../../helpers/time'

import {
  Box,
  Dialog,
  Divider,
  Heading,
  Icon,
  Inline,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  TextLink,
  WrapperButton,
} from '@leadrilla/pulsar'
import { CallContext } from './CallContext'
// import { actionType, useCallCampaignDispatch } from './CallContext'
import { TargetedState } from '../../types/targeted_state'
import { useScreenSize } from '../../hooks/useScreenSize'
import CallHistory from './CallHistory'
import CallBox from './CallBox'
import CallWindow from './CallWindow'
import CallSpinnerIcon from '../../components/CallSpinnerIcon/call_spinner_icon'
import backend from '../../backend'
import { useCampaigns } from '../../hooks/campaigns'
import CampaignPauseReasonModal from '../Campaigns/campaign_pause_reason_modal'
import { usePusher } from '../../hooks/pusher_old'
import FailedToCallUserModal from '../../components/CallLeadButton/failed_to_call_user_modal'
import { OK } from '../../constants/error_codes'
import { useTenantConfig } from '../../hooks/TenantConfig'

interface ChildComponentProps {
  history: History
}

export const CallBar: React.FC<ChildComponentProps> = ({ history }: ChildComponentProps) => {
  const [showIsPresentModal, setShowIsPresentModal] = useState(false)
  const [countdown, setCountdown] = useState(60)
  const [showInfoBox, setShowInfoBox] = useState(false)
  const [showPauseReasonModal, setShowPauseReasonModal] = useState(false)
  const [showFailedToCallUserModal, setShowFailedToCallUserModal] = useState(false)
  const [campaignToPause, setCampaignToPause] = useState<string | undefined>(undefined)
  const { updateCampaign } = useCampaigns()
  const { emit } = usePusher()
  const callContext = useContext(CallContext)
  const device = useScreenSize()
  const tenantConfig = useTenantConfig()
  const [voiceNumber, setVoiceNumber] = useState('')

  const idleEvents: EventsType[] = [
    'mousemove',
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
    'visibilitychange',
  ]

  // To simulate a non-VoIP call, enable a call campaign and then uncomment this code:
  // const dispatch = useCallCampaignDispatch()
  // useEffect(() => {
  //   if (dispatch) {
  //     dispatch({
  //       type: actionType.UPDATE,
  //       payload: {
  //         enabled: true,
  //         call: {
  //           active: true,
  //           type: 'product',
  //           product_id: '76ce30fb-a39a-4aa2-8577-a47f70154e10',
  //           created_at: new Date().toISOString(),
  //           number: '+18595555556',
  //         },
  //         lead: {
  //           id: '9231a4af-68a0-49d4-be54-50471982ac93',
  //           first_name: 'John',
  //           last_name: 'Doe',
  //           email: 'john.doe@example.com',
  //           name: 'John Doe',
  //         },
  //       },
  //     })
  //   }
  // }, [])

  // Timer / countdown activation for idle users
  const onPrompt = () => {
    setShowIsPresentModal(true)
  }

  const onIdle = () => {
    setShowIsPresentModal(false)
    if (emit) {
      emit('client-idle', {
        message: 'idle',
      })
    }

    if (updateCampaign && callContext?.campaign?.id) {
      updateCampaign(callContext.campaign.id, { enabled: false })
    }
  }

  const onActive = () => {
    startTimer()
    if (showIsPresentModal) setShowIsPresentModal(false)
  }

  const mainTimerSettings: IIdleTimerProps = {
    eventsThrottle: 50,
    events: idleEvents,
    timeout: tenantConfig.campaigns.ipp_idle_timeout,
    promptTimeout: 60_000,
    crossTab: true,
    onIdle,
    onActive,
    onPrompt,
  }

  const initialTimer = useIdleTimer(mainTimerSettings)
  const [timer] = useState(initialTimer)
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timer | undefined>()

  const startTimer = () => {
    resetTimer()

    if (timerInterval) clearInterval(timerInterval)

    const interval = setInterval(() => {
      const timeRemaining = Math.round(timer.getRemainingTime() / 1000)
      if (timeRemaining <= 180) {
        setCountdown(timeRemaining)
        const minutes = Math.floor(timeRemaining / 60)
        const seconds = timeRemaining % 60
        document.title = `Campaign pausing in ${minutes ? minutes + 'm ' : ''}${seconds
          .toString()
          .padStart(2, '0')}s`
      } else {
        document.title = tenantConfig.title
      }
    }, 1000)

    setTimerInterval(interval)
  }

  const resetTimer = () => {
    if (timerInterval) clearInterval(timerInterval)
    timer.reset()
    document.title = tenantConfig.title
  }

  const pauseTimer = () => {
    if (timerInterval) clearInterval(timerInterval)
    timer.pause()
    document.title = tenantConfig.title
  }

  useEffect(() => {
    if (!callContext?.campaign?.enabled || !callContext.ipp || callContext?.call?.active) {
      pauseTimer()
    } else if (!callContext?.call?.active && callContext?.call?.type === 'product') {
      startTimer()
    }
    if ((callContext?.campaign || callContext?.call) && device !== 'desktop') {
      try {
        //@ts-ignore
        if (window.fcWidget)
          //@ts-ignore
          window.fcWidget.hide()
      } catch (e) {}
    } else {
      try {
        //@ts-ignore
        window.fcWidget.show()
      } catch (e) {}
    }
  }, [timer, callContext, device])

  const onClose = () => {
    setShowIsPresentModal(false)
    resetTimer()
  }

  useEffect(() => {
    /* if (callContext?.campaign?.enabled) {
      console.log(callContext)
      console.log('callContext?.enabled')
      //initPusher()
    } */
    if (!showInfoBox && callContext?.call?.active) {
      setShowInfoBox(true)
    }
  }, [callContext])

  const onArrowClick = (setting: boolean) => {
    if (!callContext?.call) {
      setShowInfoBox(setting)
    }
  }

  const onPauseCampaign = async () => {
    resetTimer()
    setShowIsPresentModal(false)
    if (callContext?.campaign?.id) {
      setCampaignToPause(callContext.campaign.id)
      setShowPauseReasonModal(true)
    }
  }

  const goToLeads = () => {
    const state = {
      dataTables: [
        {
          name: 'my-leads-table',
          selected: [
            {
              key: 'product',
              selected: {
                value: callContext?.campaign?.product?.id,
                text: callContext?.campaign?.product?.name,
              },
            },
          ],
        },
      ],
    }
    history.push(`/dash/my-leads`, state)
    setShowInfoBox(false)
  }

  const goToEditPhone = () => {
    history.push(`/dash/campaigns/${callContext?.campaign?.id}/edit`, {
      currentStep: 'phone_number_step',
    })
    setShowInfoBox(false)
  }

  const enableSpinner = () => {
    return (
      !callContext.call ||
      (callContext?.call?.type !== 'product' &&
        callContext?.call?.active === true &&
        !callContext.call.accepted)
    )
  }

  const activeCall = () => {
    const call = callContext.call
    if (!call?.type) return false

    if (call.type === 'product') {
      return call.active
    } else if (call.type === 'inbound') {
      return call.active && call.answered
    } else if (call.type === 'outbound') {
      return call.active && call.accepted
    }
  }
  const getVoiceNumber = async () => {
    const {
      status,
      body: { twilio_number },
    } = await backend.get('/users/me') // todo switch to User.twilio_number.number once user context always pulls in the twilio #
    if (status === OK) {
      if (twilio_number) setVoiceNumber(twilio_number.number)
    }
  }
  useEffect(() => {
    getVoiceNumber()
  }, [])
  useEffect(() => {
    if (showInfoBox) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [showInfoBox])

  useEffect(() => {
    if (callContext?.call?.failed_user && callContext?.call?.user_lead?.lead_id) {
      // Call failed to connect to the User - show modal
      setShowFailedToCallUserModal(true)
    }
  }, [callContext])

  return (
    <>
      <FailedToCallUserModal
        open={showFailedToCallUserModal}
        setOpen={setShowFailedToCallUserModal}
        leadId={callContext?.call?.user_lead?.lead_id as string}
      />

      {(callContext?.campaign?.id ||
        (callContext?.call?.id &&
          callContext.call.type !== 'product' &&
          !(!callContext.call.active && callContext.call.outcome !== 'CONNECTED'))) && (
        <>
          {/* @ts-expect-error FIXME */}
          <AnimatePresence>
            {(showInfoBox || callContext?.call?.id) && (
              <motion.div
                style={{ position: 'relative', zIndex: 2 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.4 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <Box
                  id="pulsar-modal--backdrop"
                  position="fixed"
                  background="var(--colors-modal--backdrop)"
                  // opacity={0.4}
                  top={0}
                  right={0}
                  bottom={0}
                  left={0}
                />
              </motion.div>
            )}
          </AnimatePresence>

          <motion.div
            initial={{ y: 100, opacity: 0.9 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
          >
            <CallBarContainer mobile={device === 'mobile'}>
              <BottomBar
                background={activeCall() ? 'var(--colors-positive)' : 'var(--colors-action)'}
              />
              <Bar
                background={activeCall() ? 'var(--colors-positive)' : 'var(--colors-action)'}
                width={['100%', '600px']}
                borderRadius={device !== 'mobile'}
                id="presence-bar"
              >
                <StyledBox paddingX="l">
                  <LeftBoxWrapper>
                    <Inline space="m" alignY="center" nowrap>
                      <CallSpinnerIcon spinner={enableSpinner()} />
                      <StyledStack space="xxs">
                        <Text tone={'ghost'} size="small" truncate>
                          {callContext.campaign && (
                            <>
                              {callContext.call
                                ? `${
                                    callContext.call.active
                                      ? `Active Call: ${callContext?.campaign?.product?.name}`
                                      : `Call ended${
                                          callContext.call.duration
                                            ? ` (${formatDuration(callContext.call.duration)})`
                                            : ''
                                        }`
                                  }`
                                : 'Waiting for a call..'}
                            </>
                          )}

                          {!callContext.campaign &&
                            callContext.call &&
                            callContext.call.type !== 'product' && (
                              <>
                                {`${
                                  callContext.call.active && callContext.call.accepted
                                    ? 'Active Call'
                                    : callContext.call.type === 'outbound'
                                    ? 'Calling'
                                    : 'Call from'
                                } ${parsePhoneNumberFromString(
                                  callContext.call.type === 'outbound'
                                    ? (callContext.call.to as string)
                                    : (callContext.call.from as string),
                                  'US'
                                )?.formatNational()}`}
                              </>
                            )}
                        </Text>
                        <Text tone={'ghost'} size={['regular', 'big']} weight="stronger" truncate>
                          {callContext?.call?.type === 'product' || callContext.campaign ? (
                            <>
                              {callContext?.call?.number
                                ? parsePhoneNumberFromString(
                                    callContext?.call?.number,
                                    'US'
                                  )?.formatNational()
                                : callContext?.campaign?.product?.name + 's'}
                            </>
                          ) : (
                            <>
                              {callContext.call && (
                                <>
                                  {callContext.lead && callContext.lead.is_matched
                                    ? `${callContext.lead.first_name} ${callContext.lead.last_name}`
                                    : 'Unmatched number'}
                                </>
                              )}
                            </>
                          )}
                        </Text>
                      </StyledStack>
                    </Inline>
                  </LeftBoxWrapper>
                  <StyleGridBox>
                    {callContext.campaign && (
                      <>
                        <Box>
                          {' '}
                          {/*box is here to force arrow icon to the right when pause button is gone */}
                          {!callContext.call && device !== 'mobile' && (
                            <StyledSecondaryButton
                              size={'small'}
                              onClick={onPauseCampaign}
                              fullWidth
                              id="presence-pause-button"
                            >
                              Pause Campaign
                            </StyledSecondaryButton>
                          )}
                        </Box>
                        <StyledWrapperButton onClick={() => onArrowClick(!showInfoBox)}>
                          {!callContext?.call && (
                            <Icon
                              name="arrow"
                              rotate={showInfoBox ? 0 : 180}
                              size="l"
                              tone="ghost"
                            />
                          )}
                        </StyledWrapperButton>
                      </>
                    )}
                    {!callContext.campaign &&
                      callContext.call?.type !== 'product' &&
                      callContext?.call?.number && (
                        <Box display="flex" justifyContent="end">
                          <StyledStack space="xxs">
                            <Text tone={'ghost'} size="small" align="right" truncate>
                              {callContext.call.type === 'outbound' ? 'Calling from' : 'Call to'}
                            </Text>
                            <Text
                              tone={'ghost'}
                              size={['regular', 'big']}
                              weight="stronger"
                              truncate
                            >
                              {parsePhoneNumberFromString(
                                voiceNumber as string,
                                'US'
                              )?.formatNational()}
                            </Text>
                          </StyledStack>
                        </Box>
                      )}
                  </StyleGridBox>
                </StyledBox>
                {/* @ts-expect-error FIXME */}
                <AnimatePresence>
                  {(showInfoBox || callContext?.call) && (
                    <motion.div
                      key="box"
                      layout
                      initial={{ y: 0, scaleY: 1, opacity: 1, height: 0 }}
                      animate={{ y: 0, scaleY: 1, opacity: 1, height: 'auto' }}
                      exit={{ y: 0, scaleY: 1, opacity: 1, height: 0 }}
                      transition={{
                        duration: 0.2,
                        layout: { ease: 'linear' },
                      }}
                    >
                      <Box
                        height={['calc(100vh - 74px)', '470px']}
                        marginBottom="10px"
                        background="var(--colors-foreground)"
                      >
                        {callContext.call && callContext.call.type !== 'product' && <CallWindow />}
                        {callContext.call && callContext.call.type === 'product' && <CallBox />}
                        {callContext.campaign && !callContext.call && (
                          <StyledCampaignInfoBox
                            display={['block', 'flex']}
                            justifyContent="between"
                          >
                            <StyledCampaignColumn inset={'m'} width={['100%', '50%']}>
                              <Stack space="l">
                                <Stack space="s">
                                  <Text tone="light" size="small">
                                    Campaign Info
                                  </Text>
                                  <Text weight="strong" size="big" truncate>
                                    {callContext?.campaign?.name}
                                  </Text>
                                  <Divider />
                                </Stack>
                                <Stack space="s">
                                  <Inline alignX="between">
                                    <Stack space="s">
                                      <Text tone="light" size="small">
                                        Agent Phone Number
                                      </Text>
                                      <Text weight="strong" size="big">
                                        {callContext?.campaign?.phone
                                          ? parsePhoneNumberFromString(
                                              callContext?.campaign?.phone,
                                              'US'
                                            )?.formatNational()
                                          : ''}
                                      </Text>
                                    </Stack>
                                    <WrapperButton
                                      onClick={goToEditPhone}
                                      id="presence-click-edit-phone-number-button"
                                    >
                                      <Icon name="edit" tone="action" size="m" />
                                    </WrapperButton>
                                  </Inline>
                                  <Divider />
                                </Stack>
                                <Stack space="s">
                                  <Text tone="light" size="small">
                                    Geography
                                  </Text>
                                  <Box>
                                    <StyledGeographyText component="p" weight="strong" size="big">
                                      {callContext.campaign?.states?.map(
                                        (state: TargetedState, i: number, arr: TargetedState[]) => {
                                          return (
                                            <React.Fragment key={state.id}>
                                              {i === arr.length - 1 ? (
                                                <span>{state.state}</span>
                                              ) : (
                                                <span>{state.state},&nbsp;</span>
                                              )}
                                            </React.Fragment>
                                          )
                                        }
                                      )}
                                    </StyledGeographyText>
                                  </Box>
                                  <Divider />
                                </Stack>
                                <Stack space="s">
                                  <Text tone="light" size="small">
                                    Calls will come from
                                  </Text>
                                  <Text weight="strong" size="big">
                                    {callContext?.campaign?.product?.cid_number}
                                  </Text>
                                  <Divider />
                                </Stack>
                              </Stack>
                            </StyledCampaignColumn>
                            <Box inset={'m'} width={['100%', '50%']}>
                              <Stack space="l">
                                <Box display="flex" justifyContent="between">
                                  <Text size="big" weight="stronger">
                                    Call History
                                  </Text>
                                  <Box onClick={goToLeads} width="100%" id="show-all-leads-button">
                                    <Text align="right">
                                      {
                                        // @ts-ignore
                                        <TextLink>View all calls</TextLink>
                                      }
                                    </Text>
                                  </Box>
                                </Box>
                                <CallHistory
                                  campaign={callContext.campaign}
                                  history={history}
                                  setShowInfo={setShowInfoBox}
                                />
                              </Stack>
                            </Box>
                          </StyledCampaignInfoBox>
                        )}
                      </Box>
                    </motion.div>
                  )}
                </AnimatePresence>
              </Bar>
            </CallBarContainer>
          </motion.div>
          {callContext.campaign && (
            <CampaignPauseReasonModal
              open={showPauseReasonModal}
              setOpen={setShowPauseReasonModal}
              pauseAll={false}
              campaignId={callContext.campaign.id}
              afterSubmit={() => {
                if (updateCampaign) updateCampaign(campaignToPause as string, { enabled: false })
              }}
            />
          )}
        </>
      )}

      {showIsPresentModal && (
        <Dialog
          open={showIsPresentModal}
          onClose={onClose}
          inset="none"
          width={['full', 'content']}
        >
          <Box maxWidth={['100%', '375px']} id="countdown-modal">
            <Box inset="m">
              <Inline alignX="between" alignY="center">
                <Heading level={3}>Are you still there?</Heading>
              </Inline>
            </Box>

            <Divider />

            <Box inset="m">
              <Stack space="m">
                <Text component="p" pLineHeight={1.5}>
                  It looks like you’ve been idle for a few minutes. Are you still there?
                </Text>
                <Text component="p" pLineHeight={1.5}>
                  Your campaign will be automatically paused in {countdown} seconds.
                </Text>
                <Inline alignX="between">
                  <SecondaryButton onClick={onPauseCampaign} id="countdown-modal-pause-button">
                    Pause my campaign
                  </SecondaryButton>
                  <PrimaryButton onClick={onActive} id="countdown-modal-still-here-button">
                    Yes, I’m still here
                  </PrimaryButton>
                </Inline>
              </Stack>
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  )
}

const CallBarContainer = styled(Box)`
  width: 100%;
  background-color: var(--colors-action);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 3;
  ${(props) =>
    props.mobile
      ? `
      position: sticky;
      height: 9px;
      top: 0;`
      : `
      position: fixed;
      height: 18px;
      bottom: 0;
  `}
`
const BottomBar = styled(Box)`
  height: 18px;
  //  background-color:var(--colors-action);
  position: fixed;
  z-index: 4;
  width: 100%;
  bottom: 0;
`
const Bar = styled(Box)`
  min-height: 82px;
  position: relative;
  border-radius: ${(props) => (props.borderRadius ? '16px 16px 0 0' : 'none')};
`

const StyledSecondaryButton = styled(SecondaryButton)`
  border-color: var(--card--background);
  max-width: 120px;
  color: var(--card--background) !important;
  span {
    color: var(--card--background);
    font-size: 12px;
  }
  :hover {
    color: var(--card--background) !important;
    background: var(--button-hover--primary);
    border-color: var(--card--background) !important;
  }
`
const StyledBox = styled(Box)`
  display: grid !important;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%;
  height: 74px;
`
const LeftBoxWrapper = styled(Box)`
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const StyledStack = styled(Stack)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const StyleGridBox = styled(Box)`
  grid-row-start: 1;
  grid-row-end: 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-items: end;
  align-content: end;
  flex-direction: row;
  column-gap: 30px;
  gap: 32px;
  row-gap: 10px;
`
const StyledWrapperButton = styled(WrapperButton)`
  justify-self: end;
`

const StyledCampaignColumn = styled(Box)`
  border-right: 1px solid var(--colors-background);
`
const StyledGeographyText = styled(Text)`
  word-wrap: break-word;
`
const StyledCampaignInfoBox = styled(Box)`
  overflow-y: auto;
  max-height: calc(100vh - 74px);
`
