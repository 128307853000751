import { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Icon, Text, Divider, Badge, TextLink } from '@leadrilla/pulsar'
import styled from '@emotion/styled'
import cn from 'classnames'
import { UserContext } from '../AuthProvider/auth_provider'
import { isLeadrilla, useTenantConfig } from '../../hooks/TenantConfig'
import { formatUSD } from '../../helpers/currency'
import { AdminOptions } from './admin_options'
import poweredByLogo from '../../assets/svg/powered-by-salesriver-logo.svg'
import { PoweredByLogo } from '../PoweredByLogo/PoweredByLogo'
import { isAdminForAtLeastOneTeam } from '../../helpers/teams'
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip'

export default function Sidebar({ headerHeight }: { headerHeight: string }) {
  const { user } = useContext(UserContext)
  const tenantConfig = useTenantConfig()
  const { pathname } = useLocation()

  return (
    <aside className="mt-6 w-[200px] p-6" style={{ height: `calc(100vh - ${headerHeight})` }}>
      <div className="flex h-full flex-col justify-between">
        <div className="space-y-12">
          <StyledNavLink
            className={cn({ selected: pathname === '/dash/my-leads' })}
            to="/dash/my-leads"
          >
            <div className="flex items-center space-x-3 pl-4">
              {pathname === '/dash/my-leads' ? (
                <StyledIcon name="sell-tt"></StyledIcon>
              ) : (
                <StyledIcon name="sell"></StyledIcon>
              )}
              <StyledText>My Leads</StyledText>
            </div>
          </StyledNavLink>
          {tenantConfig.voip.enabled && tenantConfig.voip.voice.enabled && (
            <div className="flex items-center">
              <StyledNavLink
                className={cn({ selected: pathname === '/dash/call-log' })}
                to="/dash/call-log"
              >
                <div className="flex items-center space-x-3 pl-4">
                  {pathname === '/dash/call-log' ? (
                    <StyledIcon name="phone-tt"></StyledIcon>
                  ) : (
                    <StyledIcon name="phone"></StyledIcon>
                  )}
                  <StyledText>Call Log</StyledText>
                </div>
              </StyledNavLink>
            </div>
          )}
          {tenantConfig.campaigns.enabled && (
            <div className="flex items-center pl-4">
              <StyledNavLink
                className={cn({ selected: pathname === '/dash/campaigns' })}
                to="/dash/campaigns"
              >
                <div className="flex items-center space-x-3">
                  {pathname === '/dash/campaigns' ? (
                    <StyledIcon name="campaign-tt"></StyledIcon>
                  ) : (
                    <StyledIcon name="campaign"></StyledIcon>
                  )}
                  <StyledText>Campaigns</StyledText>&nbsp;
                </div>
              </StyledNavLink>
              {isLeadrilla() && (
                <Tooltip>
                  <TooltipTrigger>
                    <Icon name="help" size="s" tone="action" />
                  </TooltipTrigger>
                  <TooltipContent side="right">
                    <div className="mb-3">
                      <div className="font-extrabold">What is a campaign?</div>
                    </div>
                    A campaign is the primary way to acquire leads. We have two types of campaigns
                    (Real-Time Exclusive Leads and Live Calls) and several products within each.
                    Create a campaign to receive exclusive leads in real time.
                  </TooltipContent>
                </Tooltip>
              )}
            </div>
          )}
          {!tenantConfig.disableMarketplace && (
            <div className="flex items-center pl-4">
              <>
                <StyledNavLink
                  className={cn({ selected: pathname === '/dash/marketplace' })}
                  to="/dash/marketplace"
                >
                  <div className="flex items-center space-x-3">
                    {pathname === '/dash/marketplace' ? (
                      <StyledIcon name="marketplace-tt"></StyledIcon>
                    ) : (
                      <StyledIcon name="marketplace"></StyledIcon>
                    )}
                    <StyledText>Marketplace</StyledText>
                  </div>
                </StyledNavLink>
                &nbsp;
                {isLeadrilla() && (
                  <Tooltip>
                    <TooltipTrigger>
                      <Icon name="help" size="s" tone="action" />
                    </TooltipTrigger>
                    <TooltipContent side="right">
                      <div className="mb-3">
                        <div className="font-extrabold">What is the marketplace?</div>
                      </div>
                      The Marketplace is a secondary auction for purchasing leads. Leads that do not
                      record a sale within 21 days in a campaign are transferred to the Marketplace
                      and sold at a discount.
                    </TooltipContent>
                  </Tooltip>
                )}
              </>
            </div>
          )}
          {tenantConfig?.teams?.enabled &&
            // @ts-expect-error FIXME
            (user.type === 'admin' ||
              // @ts-expect-error FIXME
              (user?.teams?.length > 0 && isAdminForAtLeastOneTeam({ teams: user.teams }))) && (
              // Platform admins can see this page regardless
              <div className="flex items-center">
                <StyledNavLink
                  className={cn({ selected: pathname.startsWith('/dash/teams') })}
                  to="/dash/teams"
                >
                  <div className="flex items-center space-x-3 pl-4">
                    {pathname.startsWith('/dash/teams') ? (
                      <StyledIcon name="teams-tt"></StyledIcon>
                    ) : (
                      <StyledIcon name="teams"></StyledIcon>
                    )}
                    <StyledText>Teams</StyledText>
                  </div>
                </StyledNavLink>
              </div>
            )}
          <div>
            <StyledNavLink
              className={cn({ selected: pathname === '/dash/integrations' })}
              to="/dash/integrations"
            >
              <div className="flex items-center space-x-3 pl-4">
                {pathname === '/dash/integrations' ? (
                  <StyledIcon name="integration-tt"></StyledIcon>
                ) : (
                  <StyledIcon name="integration"></StyledIcon>
                )}
                <StyledText>Integrations</StyledText>
              </div>
            </StyledNavLink>
          </div>
          {!tenantConfig.navigation?.hide_faq && (
            <div>
              <StyledNavLink className={cn({ selected: pathname === '/dash/faq' })} to="/dash/faq">
                <div className="flex items-center space-x-3 pl-4">
                  {pathname === '/dash/faq' ? (
                    <StyledIcon name="learn-tt"></StyledIcon>
                  ) : (
                    <StyledIcon name="learn"></StyledIcon>
                  )}
                  <StyledText>FAQ</StyledText>
                </div>
              </StyledNavLink>
            </div>
          )}

          {(!isLeadrilla() ||
            // @ts-expect-error FIXME
            (user.impersonator && user.impersonator.email) ||
            // @ts-expect-error FIXME
            user.type === 'admin') && <Divider />}

          {/* @ts-expect-error FIXME */}
          <div>{user.type === 'admin' && <AdminOptions />}</div>
          {/* @ts-expect-error FIXME */}
          {(!isLeadrilla() || (user.impersonator && user.impersonator.email)) && (
            <>
              <div className="pl-4 pt-12">
                <StyledTextLink
                  href={`mailto:${tenantConfig.emails?.support_email}?subject=${
                    tenantConfig.emails?.support_email_subject ||
                    tenantConfig.display_name + ' Question'
                  }`}
                  target="_blank"
                  weight="weak"
                  title={tenantConfig.emails.support_email}
                >
                  <StyledDiv className="flex items-center space-x-2">
                    <StyledIcon name="support" />
                    <StyledText size="big">Need Support?</StyledText>
                  </StyledDiv>
                </StyledTextLink>
              </div>
              <div className="pl-4 pt-[96px]">
                {tenantConfig.referral_program.enabled && (
                  <NavLink to="/dash/refer">
                    {!tenantConfig.referral_program.link_only ? (
                      <StyledBadge tone="action">
                        Refer friends, earn{' '}
                        {formatUSD(tenantConfig.referral_program.referrer_amount)}!
                      </StyledBadge>
                    ) : (
                      <StyledBadge tone="action">Referral Link</StyledBadge>
                    )}
                  </NavLink>
                )}
              </div>
            </>
          )}
        </div>
        <PoweredByLogo
          className="mb-4 mt-6"
          src={poweredByLogo}
          alt="Powered by Salesriver"
          height={32}
          width={116}
        />
      </div>
    </aside>
  )
}

const StyledTextLink = styled(TextLink)`
  text-decoration: none;
  color: #515459;
  &:hover {
    color: var(--button-hover--primary);
    text-decoration: none;
  }
`

const StyledText = styled(Text)`
  color: inherit;
  font-weight: inherit;
`

const StyledIcon = styled(Icon)`
  color: inherit;
`

const StyledNavLink = styled(NavLink)`
  color: #515459;

  &:hover {
    color: var(--button-hover--primary);
  }

  &:active {
    color: var(--choice-chip-text--active);
    font-weight: 700;
  }

  &.selected {
    color: var(--colors-icon-base);
    font-weight: 600;
  }
`

const StyledBadge = styled(Badge)`
  width: 170px;
`

const StyledDiv = styled.div`
  &:hover {
    color: var(--button-hover--primary) !important;
  }
`
