import { useQuery } from '@tanstack/react-query'
import backend from '../../backend'
import { Product } from 'src/types/product'

interface LeadStatuses {
  [key: string]: {
    name: string
    tone: string
  }
}

/**
 * Fetches `LeadStatuses` for a given `Product`.
 *
 * If `productId` is provided, fetches `LeadStatuses` for the specific `Product`.
 * If `productType` is provided, fetches `LeadStatuses` for all `Product`s of the given `type`.
 * If neither `productId` nor `productType` is provided, fetches `LeadStatuses` for all `Product`s.
 */
const fetchLeadStatuses = async ({
  productId,
  productType,
}: {
  productId?: Product['id']
  productType?: Product['type']
}): Promise<LeadStatuses> => {
  const url = `/leads/${productId || productType || 'all'}/statuses`
  const { body } = await backend.get(url)
  return body
}

/**
 * Fetches `LeadStatuses` for a given `Product`.
 *
 * If `productId` is provided, fetches `LeadStatuses` for the specific `Product`.
 * If `productType` is provided, fetches `LeadStatuses` for all `Product`s of the given `type`.
 * If neither `productId` nor `productType` is provided, fetches `LeadStatuses` for all `Product`s.
 */
export const useLeadStatuses = ({
  productId,
  productType,
}: {
  productId?: Product['id']
  productType?: Product['type']
}) => {
  return useQuery<LeadStatuses>({
    queryKey: ['lead-statuses', productId, productType],
    queryFn: () => fetchLeadStatuses({ productId, productType }),
  })
}
