import { User } from 'src/types/users'
import { DateTime } from 'luxon'

/**
 * Returns true if the subscription type is tenant-paid or if the subscription is active.
 *
 * @param user - The user to check the subscription for.
 */
export const subscriptionIsActive = ({ user }: { user: User }) => {
  if (user.subscription?.type === 'tenant-paid') return true

  return user.subscription?.activated_at && !subscriptionIsExpired({ user })
}

/**
 * Returns true if the subscription is expired.
 *
 * @param user - The user to check the subscription for.
 */
export const subscriptionIsExpired = ({ user }: { user: User }) => {
  if (!user?.subscription?.expires_at) return false

  const currentDate = DateTime.local()
  const expiresAt = DateTime.fromISO(user.subscription.expires_at)

  return currentDate > expiresAt
}
